<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <!-- <scroll-to-top v-if="enableScrollToTop" /> -->
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { computed, ref, watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar, useStorage } from "@vueuse/core";

import store from "@/store";
import { useRouter } from "./@core/utils/utils.js";
import Vue from "vue";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

window.__lo_site_id = 329117;

(function () {
  var wa = document.createElement("script");
  wa.type = "text/javascript";
  wa.async = true;
  wa.src = "https://d10lpsik1i8c69.cloudfront.net/w.js";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(wa, s);
})();

export default {
  components: {
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  mounted() {
    if (!location.host.includes("localhost") && location.protocol !== "https:") {
      location.replace(`https://${location.host}`);
    }

    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "https://apps.elfsight.com/p/platform.js");
    document.head.appendChild(recaptchaScript);

    //we retrieve the global state with the userData and the moves

    const userData = JSON.parse(localStorage.getItem("userData"));

    if (userData) {
      this.$store.commit("app/setField", ["user", userData]);
      const moves = userData.moves;
      this.$store.commit("app/setField", ["lastMove", moves[moves.length - 1]]);
      this.$store.commit("app/setField", ["currentMove", moves[moves.length - 1]]);

      //Whether the user provided all the information in the forms
      if (moves[moves.length - 1]["allDataProvided"]) {
        this.$store.commit("app/setField", ["showDVLAMiniForm", false]);
        this.$store.commit("app/setField", ["showWizardForm", false]);
      }
    }
    // we retrieve the global state with the userData and the moves
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    // access a state of userPlan in computed function
    const userPlan = computed(() => store.state.app.userPlan);
    const route = useRouter();
    const currentMove = computed(() => store.state.app.currentMove);

    const intercomChat = () => {
      try {
        if (document.querySelector(".intercom-lightweight-app")) {
          document.querySelector(".intercom-lightweight-app").style.display = "block";
        }

        if (document.querySelector("#intercom-container")) {
          document.querySelector("#intercom-container").style.display = "block";
        }
      } catch (error) {}

      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/dyqvf88v";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    };

    //Logic for intercom Button - Hidding/Showing
    route.router.afterEach((to, from) => {
      try {
        if (currentMove.value['intercomId'] == null && to.path === "/login" ) {
          //Hidding Intercom Button
          try {
            document.getElementById("intercom-container").style.display = "none";
          } catch (error) {}

          try {
            document.querySelector(".intercom-lightweight-app").style.display = "none";
          } catch (error) {}
        } else {
          //Showing Intercom Button
          if (currentMove.value['intercomId']) {
            intercomChat();
          }
        }
      } catch (error) {
        // console.log(error);
      }
    });

    watch(
      () => userPlan.value.plan,
      (value) => {
        const userPlan = value;
      }
    );

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
  watch: {
    $route(route) {
      document.querySelector("body").classList.remove("background-light");
      // document.querySelector("body").classList.remove("background-home");

      if (route.name == "dashboard-home" || route.name == "PendingActions") {
        // document.querySelector("body").classList.add("background-home");
        document.querySelector("body").classList.add("background-light");
      } 
    },
  },
};
</script>
<style lang="scss">
.background-home {
  background-image: url("/main-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.background-light {
  background-color: #e5e4e4;
}

.vm-wrapper {
  .vm {
    top: 15%;
    border: 4px solid black;
    border-radius: 20px;
    padding: 10px;
    max-height: 700px;
    max-width: 600px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    input[type="text"] {
      color: black !important;
    }

    .text-danger {
      font-size: 11px;
      display: none;
      margin-top: 0.3rem;
    }

    .date-multi-select .vs__dropdown-toggle,
    .flatpickr-input {
      height: 35px;
    }

    .search,
    .input-search-container {
      height: 45px;
      box-shadow: 2px 1px 2px 0px rgba(0, 0, 0, 0.75);
      border: 1px solid black;
      border-radius: 0.4rem;

      .v-select {
        .vs__search,
        .vs__search:focus {
          font-size: inherit;
          padding-left: 16px;
          /*padding: 0px !important;*/
        }

        .vs__dropdown-menu {
          max-height: 270px;
          .vs__dropdown-option:nth-of-type(odd) {
            background: #e5f2fe;
            color: black;
          }
          .vs__dropdown-option:hover {
            background-color: #5897fb;
            color: white;
          }
        }

        .vs__dropdown-option {
          white-space: normal;
          /*font-weight: 100;*/
          padding: 5px 10px;
        }

        .vs__actions {
          padding-right: 12px;
        }

        color: black;
        font-weight: 600;

        .vs__selected {
          margin: 4px 0px 0;
          padding-left: 16px;
          font-weight: 400;
        }

        input::placeholder {
          color: #828282;
        }
      }

      .vs__dropdown-toggle {
        height: 45px;
        border-radius: 0.4rem;
        /*border-radius: 0 0.4rem 0 0;*/
        z-index: 9999999 !important;
        padding-left: 0px !important;
      }

      .vs__open-indicator {
        fill: black !important;
      }
    }

    .chip-list-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .chip {
        display: flex;
        align-items: center;
        padding: 7px;
        height: 30px;
        border-radius: 25px;
        background-color: #f1f1f1;
        margin-right: 4px;

        p {
          color: black;
          font-size: 14px;
        }

        .fa-circle-check {
          color: #1e9d68;
          font-size: 16px;
        }
      }
    }

    .label-message {
      background-color: rgb(57, 61, 74);
      border-radius: 5px;
      padding: 12px;

      p {
        line-height: 1.4;
      }
    }

    .button {
      all: unset;
      box-sizing: border-box;
      display: block;
      padding: 5px;
      padding-left: 9px;
      padding-right: 9px;
      color: white;
      text-align: center;
      letter-spacing: 1.5px;
      font-weight: bolder;
      cursor: pointer;
      border-radius: 5px;
      font-size: 12px;
    }

    .button-close {
      background-color: #f66667;
      box-shadow: 0 3px 0 #b22020;
    }

    .button-update {
      background-color: #288037;
      box-shadow: 0 3px 0 #11441f;
    }

    .button-pause {
      background-color: #f77e11;
      box-shadow: 0 3px 0 #873f04;
    }

    .button-danger-cancel {
      background-color: #f50e07;
      box-shadow: 0 3px 0 #700303;
    }

    .button-complete {
      background-color: #f65a5b;
      box-shadow: 0 5px 0 #c73b3b;
      width: 100%;
      font-weight: 600;
      font-size: 17px;
      padding-bottom: 8.5px;
      padding-top: 8.5px;
    }

    .button:active {
      box-shadow: none;
      transform: translateY(3px);
    }
  }

  .vm-titlebar {
    display: none !important;
  }

  .checkbox-wrapper-30 .checkbox {
    --bg: #fff;
    --brdr: #d1d6ee;
    --brdr-actv: #1e2235;
    --brdr-hovr: #bbc1e1;
    --dur: calc((var(--size, 2) / 2) * 0.6s);
    display: inline-block;
    width: calc(var(--size, 1) * 22px);
    position: relative;
  }
  .checkbox-wrapper-30 .checkbox:after {
    content: "";
    width: 100%;
    padding-top: 100%;
    display: block;
  }
  .checkbox-wrapper-30 .checkbox > * {
    position: absolute;
  }
  .checkbox-wrapper-30 .checkbox input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    background-color: var(--bg);
    border-radius: calc(var(--size, 1) * 4px);
    border: calc(var(--newBrdr, var(--size, 1)) * 1px) solid;
    color: var(--newBrdrClr, var(--brdr));
    outline: none;
    margin: 0;
    padding: 0;
    transition: all calc(var(--dur) / 3) linear;
  }
  .checkbox-wrapper-30 .checkbox input:hover,
  .checkbox-wrapper-30 .checkbox input:checked {
    --newBrdr: calc(var(--size, 1) * 2);
  }
  .checkbox-wrapper-30 .checkbox input:hover {
    --newBrdrClr: var(--brdr-hovr);
  }
  .checkbox-wrapper-30 .checkbox input:checked {
    --newBrdrClr: var(--brdr-actv);
    transition-delay: calc(var(--dur) / 1.3);
  }
  .checkbox-wrapper-30 .checkbox input:checked + svg {
    --dashArray: 16 93;
    --dashOffset: 109;
  }
  .checkbox-wrapper-30 .checkbox svg {
    fill: none;
    left: 0;
    pointer-events: none;
    stroke: var(--stroke, var(--border-active));
    stroke-dasharray: var(--dashArray, 93);
    stroke-dashoffset: var(--dashOffset, 94);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    top: 0;
    transition: stroke-dasharray var(--dur), stroke-dashoffset var(--dur);
  }
  .checkbox-wrapper-30 .checkbox svg,
  .checkbox-wrapper-30 .checkbox input {
    display: block;
    height: 100%;
    width: 100%;
  }
}

/*Welcome Modal Styles*/
div.swal2-container.swal2-center.swal2-backdrop-show {
  .swal2-popup {
    padding: 0px !important;
    /* border-radius: 30px !important;*/
    border-radius: 30px 30px 0px 30px !important;
  }

  .swal2-content {
    padding: 0;
  }

  .swal2-html-container {
    p {
      font-size: 17px;
      font-weight: 500;
    }
    h1,
    p {
      color: black;
    }

    margin: 0px !important;

    .user-details {
      background-color: #f0f4f8;
      border-radius: 30px;

      p {
        margin-bottom: 2px;
      }
    }
  }

  img {
    max-width: 100%;
    height: 100%;
    border-radius: 0px 30px 0px 0px;
  }

  @media screen and (max-width: 767px) {
    img {
      border-radius: 0px 0px 0px 30px;
    }
  }

  .button-sloth {
    h4 {
      color: white;
    }

    background-color: #15799b;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 11px 16px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter 0.2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
  }

  .button-sloth:after {
    background-color: #18a8d6;
    background-clip: padding-box;
    border: solid transparent;
    border-radius: 8px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .button-sloth:main,
  .button-sloth:focus {
    user-select: auto;
  }

  .button-sloth:hover:not(:disabled) {
    filter: brightness(1.1);
    -webkit-filter: brightness(1.1);
  }

  .amend-details {
    cursor: pointer;
    text-decoration: underline;
    color: #545454 !important;
  }
}

/*Slothguard Modal Styles*/
div.swal2-container.swal2-center.swal2-backdrop-show {
  .slotguard-modal {
    border-radius: 30px 30px 30px 30px !important;
    padding: 1.25rem !important;
  }
}
/* Alert Cancel Move Modal Styles*/
div.swal2-container.swal2-center.swal2-backdrop-show.alert-cancel-move {
  .swal2-popup {
    border-radius: 20px !important;
  }

  .swal2-popup {
    padding: 15px !important;
  }
}
</style>
