import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  cuckoo: {
    perMonth: '£29.99 each month',
    cost: '£60 set-up cost',
    price: '12-month price promise'
  }
}
/* eslint-disable global-require */
mock.onGet('/cuckoo/info').reply(() => [200, data.cuckoo])
