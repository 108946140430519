import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  pricing: {
    basicPlan: {
      title: 'Starter',
      img: `https://bucket-image-container.s3.amazonaws.com/pircing-mover.jpg`,
      // img: require('@/assets/images/illustration/Pot1.svg'),
      subtitle: 'A simple view to get started',
      monthlyPrice: 0,
      yearlyPlan: {
        perMonth: 0,
        totalAnual: 0,
      },
      planBenefits: [
        {
          title: "Track Address updates",
          hoverInfo: "Track the progress of your address updates with your companies",
        },
        {
          title: "Home setup services",
          hoverInfo:
            "Access to power your home with offers on broadband, energy and home care which may not be available going directly",
        }
      ],
      popular: false,
    },
    standardPlan: {
      title: 'SlothMover',
      img: `https://bucket-image-container.s3.amazonaws.com/pircing-mover.jpg`,
      // img: require('@/assets/images/illustration/Pot2.svg'),
      subtitle: 'The minimum for every home',
      monthlyPrice: 4,
      yearlyPlan: {
        perMonth: 48,
        totalAnual: 480,
      },
      planBenefits: [
        {
          title: "Track Address updates",
          hoverInfo: "Track the progress of your address updates with your companies",
        },
        {
          title: "Home setup services",
          hoverInfo:
            "Access to power your home with offers on broadband, energy and home care which may not be available going directly",
        },
        {
          title: "SlothMove rewards",
          hoverInfo:
            "Enjoy exclusive discounts from over 200 retailers including Hotels.com, Alton Towers, Odeon Cinemas, Apple, ASOS, Costa Coffee, Sainsbury’s and many more",
        },
        // {
        //   title: "Contact suppliers",
        //   hoverInfo:
        //     "Update your meter readings, request services from your local council and much more across your accounts",
        // },
        {
          title: "SlothMove Legal",
          hoverInfo:
            "Professional legal support (worth £350 an hour) when you need it across family, consumer, motoring, property, employment, personal injury and tax",
        },
        {
          title: "Unlimited updates",
          hoverInfo: "Add as many updates as you like to this move and future moves",
        }
      ],
      popular: true,
    },
    enterprisePlan: {
      title: 'Jungle Sovereign',
      img: `https://bucket-image-container.s3.amazonaws.com/pircing-jungle-sovereign.jpg`,
      // img: require('@/assets/images/illustration/Pot3.svg'),
      subtitle: 'Only the best for this jungle sovereign',
      monthlyPrice: 7,
      yearlyPlan: {
        perMonth: 84,
        totalAnual: 960,
      },
      planBenefits: [
        {
          title: "Track Address updates",
          hoverInfo: "Track the progress of your address updates with your companies",
        },
        {
          title: "Home setup services",
          hoverInfo:
            "Access to power your home with offers on broadband, energy and home care which may not be available going directly",
        },
        {
          title: "SlothMove rewards",
          hoverInfo:
            "Enjoy exclusive discounts from over 200 retailers including Hotels.com, Alton Towers, Odeon Cinemas, Apple, ASOS, Costa Coffee, Sainsbury’s and many more",
        },
        // {
        //   title: "Contact suppliers",
        //   hoverInfo:
        //     "Update your meter readings, request services from your local council and much more across your accounts",
        // },
        {
          title: "SlothMove Legal",
          hoverInfo:
            "Professional legal support (worth £350 an hour) when you need it across family, consumer, motoring, property, employment, personal injury and tax",
        },
        {
          title: "Unlimited updates",
          hoverInfo: "Add as many updates as you like to this move and future moves",
        },
        {
          title: "SlothMove Security",
          hoverInfo:
            "Manage end-to-end security for home, phone, email and personal data one place. SlothMove Security helps you remove spam communications, understand data vulnerabilities and even protect your home from fraud",
        },
        {
          title: "Whatsapp Support",
          hoverInfo:
            "Chat with a SlothMove specialist on Whatsapp for help with your move, setup or more",
        },
      ],
      popular: false,
    },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans:
          'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
      },
      {
        question: 'Can I store the item on an intranet so everyone has access?',
        ans:
          'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
      },
      {
        question: 'Am I allowed to modify the item that I purchased?',
        ans:
          'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
      },
    ],
  },
}
/* eslint-disable global-require */
mock.onGet('/pricing/data').reply(() => [200, data.pricing])
