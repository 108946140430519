import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  cuckoo: {
    // basicPlan: {
    //   header: '80MB FIBRE',
    //   title: 'Fast',
    //   img: require('@/assets/images/illustration/Pot1.svg'),
    //   subtitle: 'Brilliant for smaller homes with up to 5 devices. Stream in 4K on 2 devices at the same time, game and download big files.',
    //   monthlyPrice: 0,
    //   disabled: false,
    //   messageButton: "I want this speed",
    //   yearlyPlan: {
    //     perMonth: 0,
    //     totalAnual: 0,
    //   },
    //   planBenefits: [
    //     '59-74Mb/s avg. download',
    //     '15-19Mb/s avg. upload',
    //     'Fibre to your street',
    //     'Landline included',
    //   ],
    //   popular: false,
    // },
    // standardPlan: {
    //   header: '115MB FULL FIBRE',
    //   title: 'Standard',
    //   img: require('@/assets/images/illustration/Pot2.svg'),
    //   subtitle: 'For small to medium businesses',
    //   monthlyPrice: 49,
    //   disabled: true,
    //   messageButton: 'Not available yet',
    //   yearlyPlan: {
    //     perMonth: 40,
    //     totalAnual: 480,
    //   },
    //   planBenefits: [
    //     'Unlimited responses',
    //     'Unlimited forms and surveys',
    //     'Instagram profile page',
    //     'Google Docs integration',
    //     'Custom “Thank you” page',
    //   ],
    //   popular: true,
    // },
    // enterprisePlan: {
    //   header: '1GB FULL FIBRE',
    //   title: 'Enterprise',
    //   img: require('@/assets/images/illustration/Pot3.svg'),
    //   subtitle: 'Solution for big organizations',
    //   monthlyPrice: 99,
    //   disabled: true,
    //   messageButtom: 'Not available yet',
    //   yearlyPlan: {
    //     perMonth: 80,
    //     totalAnual: 960,
    //   },
    //   planBenefits: [
    //     'PayPal payments',
    //     'Logic Jumps',
    //     'File upload with 5GB storage',
    //     'Custom domain support',
    //     'Stripe integration',
    //   ],
    //   popular: false,
    // },

    descriptions: [
      {
        id: '1',
        title: 'Choice of contract',
        info: 'Roll monthly or pick a 12-month plan? Totally up to you. Switch to broadband that starts good, stays good and does good for the long term.'
      },
      {
        id: '2',
        title: '12-month price promise',
        info: 'Whatever the next 12 months brings, a price rise for your broadband won\'t be one of them.'
      },
      {
        id: '3',
        title: 'Eggsperts in the UK',
        info: 'Fairness, total transparency and always a real person in the UK at the end of a phone. Experience customer service that actually, truly, properly puts you first.'
      },
      {
        id: '4',
        title: 'Optional Cuckoo router',
        info: 'We include the Technicolor DWA0122 for free. It’s worth £82+ RRP and is super easy to set up. All we ask is that you send it back if you ever leave Cuckoo (we’ll give you a freepost bag).'
      }
    ]
  },
}
/* eslint-disable global-require */
mock.onGet('/cuckoo/speed').reply(() => [200, data.cuckoo])
