export default [
  {
    path: "/dashboard/home",
    name: "dashboard-home",
    component: () => import("@/views/dashboard/ecommerce/Ecommerce.vue"),
  },
  {
    path: "/dashboard/pending-actions",
    name: "PendingActions",
    component: () => import("@/views/dashboard/pending-actions/PendingActions.vue"),
  },
  //Admin
  {
    path: "/dashboard/slothmove",
    name: "SlothMove",
    component: () => import("@/views/dashboard/admin/SlothMove.vue"),
  },
  {
    path: "/dashboard/slothguard",
    name: "SlothGuard",
    component: () => import("@/views/dashboard/admin/SlothGuard.vue"),
  },
  {
    path: "/dashboard/slothutility",
    name: "SlothUtility",
    component: () => import("@/views/dashboard/admin/SlothUtility.vue"),
  },
  {
    path: "/dashboard/slothprotect",
    name: "SlothProtect",
    component: () => import("@/views/dashboard/admin/SlothProtect.vue"),
  },
  {
    path: "/dashboard/parking-ticket-fighter",
    name: "ParkingTicketFighter",
    component: () => import("@/views/dashboard/admin/ParkingTicketFighter.vue"),
  },
  {
    path: "/dashboard/slothqueue",
    name: "SlothQueue",
    component: () => import("@/views/dashboard/admin/SlothQueue.vue"),
  },
  {
    path: "/dashboard/life-admin",
    name: "LifeAdmin",
    component: () => import("@/views/dashboard/admin/LifeAdmin.vue"),
  },
  //Admin
  {
    path: "/dashboard/cuckoo",
    name: "dashboard-cuckoo",
    component: () => import("@/views/dashboard/cuckoo/Index.vue"),
    children: [
      {
        path: "",
        name: "dashboard-cuckoo-speed",
        component: () => import("@/views/dashboard/cuckoo/Speed.vue"),
      },
      {
        path: "speed",
        name: "dashboard-cuckoo-speed",
        component: () => import("@/views/dashboard/cuckoo/Speed.vue"),
      },
      {
        path: "contact",
        name: "dashboard-cuckoo-contact",
        component: () => import("@/views/dashboard/cuckoo/Contact.vue"),
      },
      {
        path: "info",
        name: "dashboard-cuckoo-info",
        component: () => import("@/views/dashboard/cuckoo/Info.vue"),
      },
      {
        path: "payment",
        name: "dashboard-cuckoo-payment",
        component: () => import("@/views/dashboard/cuckoo/Payment.vue"),
      },
    ],
  },

  {
    path: "/dashboard/security",
    name: "dashboard-security",
    component: () => import("@/views/dashboard/security/Security.vue"),
  },
];
