export default [
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/apps/users/view/:id",
    name: "apps-users-view",
    component: () => import("@/views/apps/user/users-view/UsersView.vue"),
  },
  {
    path: "/apps/users/edit",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },
  {
    path: "/apps/legal-care",
    name: "legal-care",
    component: () => import("@/views/apps/legal-care/LegalCare.vue"),
  },
];
