import Vue from 'vue'
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: '/apiSlothmover',
  headers: {
    "Content-Type": "application/json"
  }
})

axiosIns.interceptors.request.use((request) => {
	const token = localStorage.getItem('accessToken');

	if (token) {
		request.headers['Authorization'] = `Bearer ${token}`;
		request.headers['Access-Control-Max-Age'] = 600;
	}
	return request;
});

Vue.prototype.$http = axiosIns

export default axiosIns
