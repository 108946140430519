import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// Apps
import './data/apps/user'

// dashboard
import './data/dashboard/ecommerce'

// pages
import './data/pages/faq-data'
import './data/pages/pricing-data'
import './data/pages/cuckoo-speed-data'
import './data/pages/cuckoo-contact-data'
import './data/pages/cuckoo-info-data'
import './data/pages/account-setting'
import './data/pages/profile-data'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
