import { $themeBreakpoints } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    baseURL: "/apiSlothmover",
    urlTest: "http://ec2-18-116-203-41.us-east-2.compute.amazonaws.com:8090",
    urlBucket: "https://drmig4hxr8n3m.cloudfront.net",
    //"https://bucket-image-container.s3.amazonaws.com"
    urlForm: `https://form.slothmove.com/`,
    logoPartner: "",
    user: {},
    userPlan: { plan: undefined, endDate: "" },
    windowWidth: 0,
    shallShowOverlay: false,
    tableDataServices: [], // you can test with this datafake the card with the progress bar -> [{ status: "Complete" }, { status: "Complete" }, { status: "Complete" }]
    currentMove: {}, //the currentMove is for the dynamic filling of the table in "EcommerceCompanyTable.vue", the change of the card in the home and the positioning of the marker on the map.
    lastMove: {},
    displayLabelAboutData: false,
    renderTableServices: true,
    //Miniforms
    showWizardForm: true,
    showDVLAMiniForm: false,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    isAPartner(state) {
      const { referrer } = state.currentMove;

      console.log("Partner", referrer);

      return (
        referrer !== "DEVELOPMENT" &&
        referrer !== "SlothMove" &&
        referrer !== "TaylorRose" &&
        referrer !== "storyHomes"
      );
    },
    colorPartner(state, getters) {
      const { referrer } = state.currentMove;

      const setLogo = (logo) => {
        state["logoPartner"] = logo;
      };

      if (getters.isAPartner) {
        if (referrer == "onTheMarket") {
          setLogo("onTheMarket-2");
          return "#23a9d7";
        }
      } else {
        return false;
      }
    },
  },
  mutations: {
    setField(state, [fieldName, payload]) {
      state[fieldName] = payload;
    },
    // this.$store.commit("app/setField", ["FIELD", value);
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
  },
  actions: {},
};
