import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  cuckoo: [
    {
      id: 1,
      header: 'FLEXIBLE',
      title: '1-month rolling',
      subtitle: 'Hello flexibility. Fast fibre for as long as you need it. No commitments, no fuss, no exit fees.',
      footer: 'One-off £60 set-up fee',
      monthlyPrice: 0,
      disabled: false,
      messageButton: "I want this contact",
      popular: false,
    },
    {
      id: 2,
      header: 'FREE SET-UP',
      title: '12-month',
      subtitle: 'Switch for free to broadband that starts good, stays good and does good for the long term.',
      footer: 'Save £60 with FREE set-up',
      monthlyPrice: 0,
      disabled: false,
      messageButton: "I want this contact",
      popular: false,
    },
  ]
}
/* eslint-disable global-require */
mock.onGet('/cuckoo/contact').reply(() => [200, data.cuckoo])
